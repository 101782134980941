
import { isMobile } from '@/utils/utils'
import eventBus from '@/utils/event-bus'
import { getLoginQrImg } from '@/api/login'
import Gencode from '@/components/gencode/index.vue'
import { getSecondNodes } from '@/api/policy'

const cmsNewsUrl = process.env.CMS_NEWS_URL
const __default__ = {
  name : 'HomeDefault',
  components : {
    Gencode
  },
  layout : 'base',
  scrollToTop : true,
  props : {
    data : Object
  },
  data() {
    return {
      cmsNewsUrl,
      newsIcon : require( 'assets/images/indexHome/1.png' ),
      qaIcon : require( 'assets/images/indexHome/2.png' ),
      nodesIcon : require( 'assets/images/indexHome/3.png' ),
      tIcon : require( 'assets/images/indexHome/policy3.png' ),
      qa1Icon : require( 'assets/images/indexHome/icon_admin.png' ),
      qa2Icon : require( 'assets/images/indexHome/qa.png' ),
      text : '',
      qrCreateList : [],
      secondNodes : []
    }
  },
  computed : {
    newsData : function() {
      return this.data.newsData
    },
    questionList : function() {
      return this.data.questionList
    },
    scrollHeight : function() {
      return '-' + ( this.secondNodes.length * 30 - 10 * 30 ) + 'px'
    },
    scrollTime : function() {
      return this.secondNodes.length * 1 + 's'
    }
  },
  mounted() {
    const _this = this
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua && !this.$route.query.pc ) {
        window.location.href = `/redirect/mobile`
      }
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        const qrImg = localStorage.getItem( 'loginQrImg' )
        const qrScene = localStorage.getItem( 'loginQrScene' )

        if ( qrImg == null || qrImg == undefined || qrImg == '' || qrScene == null || qrScene == undefined || qrScene ==
          '' ) {
          const r = getLoginQrImg( process.env.MINI_PG_VERSION )
          r.then( ( res ) => {
            if ( res.code == 200 ) {
              localStorage.setItem( 'loginQrImg', res.data )
              localStorage.setItem( 'loginQrScene', res.scene )
            }
          } )
        }
      }
    }
    this.qrCreateList = JSON.parse( JSON.stringify( this.$store.getters.qrList ) )
    eventBus.$on( 'loginOver', k => {
      if ( _this.$store.getters.token ) {
        _this.closedLogin()
      }
    } )

    getSecondNodes().then( res => {
      res.rows.map( i => {
        i.createTime = i.createTime.split( ' ' )[0]
        i.companyNameText = i.companyName.substr( 0, 3 ) + '...'
        i.industryText = i.industry.substr( 0, 3 ) + '...'
        if ( i.website ) {
          if ( i.website.indexOf( 'http' ) < 0 ) {
            i.website = `http://${i.website}`
          }
        }
      } )
      this.secondNodes = res?.rows || []
    } )
  },
  methods : {
    openLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    closedLogin() {
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        if ( this.qrCreateList.length > 0 ) {
          setTimeout( () => {
            this.saveQrToUser()
          }, 800 )
        }
      }
    },
    goToMore( v ) {
      if ( v === 1 ) {
        window.open( process.env.CMS_NEWS_URL )
      } else if ( v === 2 ) {
        eventBus.$emit( 'activeIndex', '6' )
        this.$router.push( { path : '/community' } )
      }
    },
    goToComm() {
      eventBus.$emit( 'activeIndex', '6' )
    },
    toBigScreen() {
      this.$router.push( { path : '/map' } )
    },
    toBigScreen1() {
      this.$router.push( { path : '/scanMap' } )
    },
    toPlainText( html ) {
      return html?.replace( /<[^>]*>/g, '' ).replace( /[\r\n\s]/g, '' ).trim()
    },
    downQR( item ) {
      const aLink = document.createElement( 'a' )
      aLink.style.display = 'none'
      aLink.href = item.img
      aLink.download = '二维码.png'
      document.body.appendChild( aLink )
      aLink.click()
      document.body.removeChild( aLink )
    }
  }
}


import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "2a81604a": (_vm.scrollTime),
  "237682b5": (_vm.scrollHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__